
import { computed, defineComponent, ref, watch } from 'vue';

import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../components/Frontend/Footer.vue';
// import Slider from '../../components/Slider.vue';
// import SliderItem from '../../components/SliderItem.vue';
// import { mdiChevronUp } from '@mdi/js';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Aboservice und Werbelösungen | Styria Media Group',
      keywords: ['service', 'aboservice', 'abo', 'werbelösung', 'werbung', 'styria'],
      description: 'Haben Fragen zu Ihrem Abo oder wollen neuen Kontakte als Werbekunde knüpfen? Wir freuen uns auf Sie.',
      image: require('@/assets/img/og/service.png'),
    },
    en: {
      title: 'subscription and andvertising | Styria Media Group',
      keywords: ['service', 'subscription', 'advertising', 'ads', 'styria'],
      description:
        'Do you have questions about your subscription or would you like to make new contacts as an advertising customer? We look forward to hearing from you.',
      image: require('@/assets/img/og/service.png'),
    },
  },
  components: {
    SocialMediaIconBox,

    //Slider,
    //SliderItem,
    Footer,
  },
  setup() {
    const iam = ref(0);
    const company = ref(0);
    const companies = {
      1: [
        //{ name: 'DIVA', id: 6 },
        { name: 'Die Furche', id: 2 },
        { name: 'Die Presse', id: 3 },
        { name: 'Kleine Kinderzeitung', id: 7 },
        { name: 'Kleine Zeitung', id: 4 },
        { name: 'Pausenzeitung', id: 8 },
        { name: 'SPORTaktiv', id: 1 },
        //{ name: 'WIENERIN', id: 5 },
      ],
      2: [
        { name: 'Content Performance Group', id: 9 },
        //{ name: 'DIVA', id: 6 },
        { name: 'Die Furche', id: 2 },
        { name: 'Die Presse', id: 3 },
        //{ name: 'ichkoche.at', id: 10 },
        { name: 'Kleine Kinderzeitung', id: 7 },
        { name: 'Kleine Zeitung', id: 4 },
        { name: 'Pausenzeitung', id: 8 },
        { name: 'SPORTaktiv', id: 1 },
        //{ name: 'WIENERIN', id: 5 },
      ],
    };

    const selectedCompanies = computed(() => {
      if (iam.value == 1 || iam.value == 2) {
        return companies[iam.value];
      }
      return [];
    });

    watch(iam, () => {
      company.value = 0;
    });

    const store = useStore();
    const i18n = useI18n();
    const firstname = ref('');
    const surname = ref('');
    const text = ref('');
    const email = ref('');
    const dsvgo = ref(false);
    const error = ref('');

    const path = `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`;
    const sent = ref(false);
    const send = () => {
      error.value = '';

      if (!company.value || company.value == 0) {
        error.value = i18n.t('General.formerror-company');
        return;
      }

      if (!iam.value || iam.value == 0) {
        error.value = i18n.t('General.formerror-iam');
        return;
      }

      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-firstname');
        return;
      }

      if (!surname.value || surname.value == '') {
        error.value = i18n.t('General.formerror-surname');
        return;
      }
      if (!email.value || email.value == '') {
        error.value = i18n.t('General.formerror-email');
        return;
      }
      if (!text.value || text.value == '') {
        error.value = i18n.t('General.formerror-text');
        return;
      }
      if (error.value == '') {
        store
          .dispatch('sendMail', {
            type: 'service',
            locale: i18n.locale.value,
            data: {
              type: iam.value,
              company: company.value,
              firstname: firstname.value,
              surname: surname.value,
              text: text.value,
              email: email.value,
            },
          })
          .then(() => {
            sent.value = true;
          });
      }
      return;
    };

    return {
      sent,
      firstname,
      surname,
      text,
      email,
      error,
      send,
      iam,
      company,
      selectedCompanies,
      mdiFormatQuoteClose,
      path,
    };
  },
});
